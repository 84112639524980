<template>
	<div id="content">
		<Header v-bind:infos="infos" />
		<main>
			<!--Section caractéristiques générales-->
			<div class="container caracteristiquesGeneralesProjet">
				<div class="row">
					<!--Le commanditaire du projet-->
					<div
						class="commanditaireProjet col-sm-12 col-md-12 col-lg-4 col-xl-4"
					>
						<div>
							<h2>COMMANDITAIRE</h2>
							<p>Projet personnel</p>
						</div>
					</div>

					<!--La date de réalisation du projet-->
					<div
						class="dateProjet offset-lg-1 offset-xl-1 col-sm-12 col-md-12 col-lg-3 col-xl-3"
					>
						<div>
							<h2>ANNÉES</h2>
							<p>2022</p>
						</div>
					</div>

					<!--Les services apportés pour ce projet-->
					<div class="servicesProjet col-sm-12 col-md-12 col-lg-4 col-xl-4">
						<div>
							<h2>SERVICES</h2>
							<p>
								Développement front-end/back-end, création d'un BOT Discord,
								déploiement
							</p>
						</div>
					</div>
				</div>
			</div>

			<!--Section présentation du projet-->
			<div class="container">
				<div class="row">
					<p class="contenuProjet col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Au cours de mon année de césure, un ami à moi avait besoin d'un BOT
						sur l'application Discord lui permettant de planifier et
						d'automatiser facilement l'envoi de messages à des intervalles
						personnalisés. Je lui ai ainsi proposé mon aide.<br /><br />
						Pour créer un BOT Discord, l'application propose aux développeurs
						d'accéder à leur API grâce à leur librairie discord.js (utilisant
						Node.js) ou discord.py (utilisant Python). Par rapport aux fonctions
						que doit réaliser le BOT, j'ai tout de suite pensé au principe des
						CRONs qui permettent de planifier des tâches de façon automatique en
						exécutant automatiquement des commandes, des scripts ou des
						logiciels de façon autonome selon un cycle défini à l’avance par
						l’utilisateur ou à une date et une heure spécifiée aussi à
						l’avance.<br /><br />
						Ainsi, j'ai utilisé la librairie discord.js (en version 13) combiné
						à MongoDB pour la gestion de la base de données. J'ai tout d'abord
						fait en sorte d'ajouter le serveur dans lequel le BOT a rejoint un
						serveur Discord à la base de données en créant un modèle comportant
						son identifiant ainsi que les CRON qui seront ajoutés. J'ai ensuite
						créé les différentes méthodes de base pour la persistance des
						données (CRUD) utilisables sur l'application avec un grand nombre
						d'options disponibles (voir la documentation du BOT pour plus de
						détails sur celles-ci). Pour finir, j'ai aussi ajouté une commande
						/help pour afficher les détails de chacune de ces commandes.<br /><br />
						De cela, j'ai ensuite utilisé la dépendance "cron-job-manager" qui
						m'a permis de gérer et d'activer tous les CRONs qui ont été ajoutés
						sur le BOT sur tous les serveurs où il se trouve. Pour finir, après
						avoir réglé tous les bugs probables que le BOT aurait pu subir et
						après avoir ajouté des permissions sur les commandes disponibles,
						j'ai déployé le BOT en production sur la plateforme Daki où il est
						actuellement actif, fonctionnel et utilisé par un petit nombre de
						serveurs.
					</p>

					<div
						class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
						style="margin-top: -3vh;"
					>
						<a
							href="https://discord.com/api/oauth2/authorize?client_id=951578400542310491&permissions=8&scope=applications.commands%20bot"
							class="boutonVoirSite"
							>LIEN D'INVITATION DU BOT</a
						>
					</div>

					<div
						class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
						style="margin-top: -3vh;"
					>
						<a
							href="https://github.com/Antonin-Winterstein/CRON_Manager"
							class="boutonVoirSite"
							>LIEN DU GITHUB DU PROJET AVEC DOCUMENTATION</a
						>
					</div>

					<!--Image 1-->

					<img
						src="../assets/cronmanager/bot_bdd.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Aperçu de la base de données MongoDB"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Aperçu de la base de données MongoDB
					</p>
				</div>
			</div>

			<div class="container">
				<!--Bouton voir les autres projets-->
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
						<router-link to="projets" class="boutonVoirTousLesProjets"
							>VOIR LES AUTRES PROJETS</router-link
						>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
	metaInfo: function() {
		return {
			title: "Antonin Winterstein | Cron Manager",
			meta: [
				{
					name: "description",
					content:
						"Découvrez le projet de création d'un BOT Discord réalisé par Antonin Winterstein !",
				},
			],
		};
	},
	components: {
		Header,
	},
	data() {
		return {
			infos: {
				titrePage: "CRON MANAGER - BOT DISCORD",
				urlImage: require("../assets/projets/fond_cron_manager.jpg"),
			},
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style>
@import "../styles/pageProjet.css";
</style>
